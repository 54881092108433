// src/pages/LoginInstructions.js

import React from "react";
import { useTranslation } from "react-i18next";

const LoginInstructions = () => {
  const { t } = useTranslation("homepage");

  const androidInstructions = t("androidInstruction").split("\n");
  const iosInstructions = t("iosInstruction").split("\n");

  return (
    <div className="flex flex-col items-center justify-start min-h-screen">
      <div className="bg-white text-center max-w-lg w-full mt-2">
        <h1 className="text-3xl font-semibold text-charcoal mt-4 mb-2">
          {t("login_instructions")}
        </h1>

        {/* Android Instructions */}

        <p>{t("already_registred")}</p>
        <h2 className="text-2xl font-semibold text-sandyBrown mt-4 mb-2">
          {t("onAndroid")}
        </h2>
        <ul className="text-left text-charcoal list-disc list-inside">
          {androidInstructions.map((instruction, index) => (
            <li key={index} className="my-1">
              {instruction}
            </li>
          ))}
        </ul>

        {/* iOS Instructions */}
        <h2 className="text-2xl font-semibold text-sandyBrown mt-4 mb-2">
          {t("onIOS")}
        </h2>
        <ul className="text-left text-charcoal list-disc list-inside">
          {iosInstructions.map((instruction, index) => (
            <li key={index} className="my-1">
              {instruction}
            </li>
          ))}
        </ul>
        <br />
        <p className="text-left text-charcoal">{t("after_registration")}</p>
        <br />
        <p className="text-left text-charcoal">{t("alternative")}</p>

        <br />
        <p className="text-left text-charcoal">{t("logging_in")}</p>
      </div>
    </div>
  );
};

export default LoginInstructions;
