// src/pages/TenantRegister.js
import React, { useState } from "react";
import config from "../../config"; // Import config

const TenantRegister = () => {
  const [data, setData] = useState({
    name: "",
    password: "",
    address: "",
    city: "",
    country: "",
    email: "",
    is_active: true,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Send data to your backend API
    try {
      const response = await fetch(`${config.API_URL}/tenant/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Failed to create");
      }

      // Handle successful creation
      //alert("Created successfully!");
      setData({
        name: "",
        password: "",
        address: "",
        city: "",
        country: "",
        email: "",
        is_active: true,
      });
    } catch (error) {
      console.error(error);
      // alert("Error creating");
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="max-w-md mx-auto p-4 border border-gray-300 rounded"
    >
      <h2 className="text-xl font-bold mb-4">Create</h2>

      <div className="mb-4">
        <label
          className="block text-charcoal font-semibold mb-1"
          htmlFor="name"
        >
          name
        </label>
        <input
          type="text"
          id="name"
          name="name"
          value={data.name}
          onChange={handleChange}
          required
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-persianGreen"
        />
      </div>

      <div className="mb-4">
        <label
          className="block text-charcoal font-semibold mb-1"
          htmlFor="password"
        >
          password
        </label>
        <input
          type="password"
          id="password"
          name="password"
          value={data.password}
          onChange={handleChange}
          required
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-persianGreen"
        />
      </div>

      <div className="mb-4">
        <label
          className="block text-charcoal font-semibold mb-1"
          htmlFor="address"
        >
          address
        </label>
        <input
          type="text"
          id="address"
          name="address"
          value={data.address}
          onChange={handleChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-persianGreen"
        />
      </div>

      <div className="mb-4">
        <label
          className="block text-charcoal font-semibold mb-1"
          htmlFor="city"
        >
          city
        </label>
        <input
          type="text"
          id="city"
          name="city"
          value={data.city}
          onChange={handleChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-persianGreen"
        />
      </div>

      <div className="mb-4">
        <label
          className="block text-charcoal font-semibold mb-1"
          htmlFor="country"
        >
          country
        </label>
        <input
          type="text"
          id="country"
          name="country"
          value={data.country}
          onChange={handleChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-persianGreen"
        />
      </div>

      <div className="mb-4">
        <label
          className="block text-charcoal font-semibold mb-1"
          htmlFor="email"
        >
          email
        </label>
        <input
          type="email"
          id="email"
          name="email"
          value={data.email}
          onChange={handleChange}
          required
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-persianGreen"
        />
      </div>

      <div className="mb-4">
        <label
          className="block text-charcoal font-semibold mb-1"
          htmlFor="is_active"
        >
          active
        </label>
        <input
          type="checkbox"
          id="is_active"
          name="is_active"
          checked={data.is_active}
          onChange={(e) => setData({ ...data, is_active: e.target.checked })}
        />
      </div>

      <button type="submit" className="bg-blue-500 text-white rounded p-2">
        Create
      </button>
    </form>
  );
};

export default TenantRegister;
