// src/components/DateButtons.js

import React from "react";

const DateButtons = ({ selectedDate, handleDateClick }) => {
  const today = new Date();
  const userLocale = navigator.language || "en-US"; // Detect user's locale, fallback to "en-US"

  return (
    <div className="grid grid-cols-7 gap-1">
      {[...Array(21)].map((_, i) => {
        const date = new Date(today);
        date.setDate(today.getDate() + i);
        const formattedDate = date.toLocaleDateString(userLocale, {
          month: "short",
          day: "numeric",
        });
        const isoDate = date.toISOString().split("T")[0]; // For key and handling click

        return (
          <button
            key={isoDate}
            onClick={() => handleDateClick(isoDate)}
            className={`m-2 p-1 border rounded ${
              selectedDate === isoDate
                ? "bg-blue-500 text-white"
                : "bg-gray-200"
            }`}
          >
            {formattedDate}
          </button>
        );
      })}
    </div>
  );
};

export default DateButtons;
