// Filename: src/pages/UserRegister.js

import React, { useEffect, useState, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TaskSelection from "../components/TaskSelection";
import config from "../config";
import { TenantContext } from "../contexts/TenantProvider";

const UserRegister = ({ onLogin }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    username: "",
    employee_nr: "",
    email: "",
    password: "",
    confirmPassword: "",
    role: "employee",
  });
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [errorMessages, setErrorMessages] = useState({
    username: "",
    employee_nr: "",
    email: "",
    password: "",
    confirmPassword: "",
    tasks: "",
    general: "",
  });

  const tenant = useContext(TenantContext);

  // Assuming tenantId is available in the tenant context:
  const tenantId = tenant?.tenantId;
  const tenantName = tenant?.tenantName || "Tenant Name";

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrorMessages({
      username: "",
      password: "",
      email: "",
      confirmPassword: "",
      general: "",
      tasks: "",
    });

    // Validate username and password for spaces
    if (/\s/.test(formData.username)) {
      setErrorMessages((prevState) => ({
        ...prevState,
        username: t("usernameCannotContainSpaces"),
      }));
      return;
    }

    if (/\s/.test(formData.password)) {
      setErrorMessages((prevState) => ({
        ...prevState,
        password: t("passwordCannotContainSpaces"),
      }));
      return;
    }

    // Validate email for spaces
    if (/\s/.test(formData.email)) {
      setErrorMessages((prevState) => ({
        ...prevState,
        email: t("emailCannotContainSpaces"),
      }));
      return;
    }

    // Validate password confirmation
    if (formData.password.trim() !== formData.confirmPassword.trim()) {
      setErrorMessages((prevState) => ({
        ...prevState,
        confirmPassword: t("passwordMismatch"),
      }));
      return;
    }

    // Validate tasks selection
    if (selectedTasks.length === 0) {
      setErrorMessages((prevState) => ({
        ...prevState,
        tasks: t("atLeastOneTaskRequired"),
      }));
      return;
    }

    const dataToSubmit = {
      ...formData,
      tasks: selectedTasks,
      tenantId, // Ensure tenantId is part of the data being sent to the backend
    };
    console.log(dataToSubmit);
    fetch(`${config.API_URL}/user/register`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataToSubmit),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          localStorage.setItem("userId", data.userId);
          localStorage.setItem("userName", data.userName);
          localStorage.setItem("userRole", data.role);

          onLogin(); // Set isLoggedIn to true
          navigate("/");
        } else {
          const errorMessage = data.message || t("registrationFailed");
          setErrorMessages((prevState) => ({
            ...prevState,
            general: errorMessage,
          }));
        }
      })
      .catch((error) => {
        console.error("Registration error:", error);
        setErrorMessages((prevState) => ({
          ...prevState,
          general: t("errorOccurred"),
        }));
      });
  };

  return (
    <div className="max-w-md mx-auto mt-6 p-4 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-bold mb-4 text-center text-charcoal">
        {t("register")}
      </h2>
      {errorMessages.general && (
        <p className="text-red-500 mb-4 text-center">{errorMessages.general}</p>
      )}
      {t("tenant_name")}: {tenantName}
      <form onSubmit={handleSubmit} className="space-y-4 mt-4">
        <div>
          <label
            htmlFor="username"
            className="block text-charcoal font-semibold mb-1"
          >
            {t("username")}:
          </label>
          <input
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-persianGreen"
            title={t("username_instruction")} // Tooltip that shows on hover
          />
          {errorMessages.username && (
            <p className="text-red-500">{errorMessages.username}</p>
          )}
          <small className=" text-charcoal text-s font-font-semibold mt-1">
            {t("username_hint")}
          </small>
        </div>

        <div>
          <label
            htmlFor="employee_nr"
            className="block text-charcoal font-semibold mb-1"
          >
            {t("employeeNumber")}:
          </label>
          <input
            type="text"
            id="employee_nr"
            name="employee_nr"
            value={formData.employee_nr}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-persianGreen"
          />
          {errorMessages.employee_nr && (
            <p className="text-red-500">{errorMessages.employee_nr}</p>
          )}
        </div>

        {/* Email Field */}
        <div>
          <label
            htmlFor="email"
            className="block text-charcoal font-semibold mb-1"
          >
            {t("email")}:
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-persianGreen"
          />
          {errorMessages.email && (
            <p className="text-red-500">{errorMessages.email}</p>
          )}
        </div>

        <div>
          <label
            htmlFor="password"
            className="block text-charcoal font-semibold mb-1"
          >
            {t("password")}:
          </label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-persianGreen"
          />
          {errorMessages.password && (
            <p className="text-red-500">{errorMessages.password}</p>
          )}
        </div>

        <div>
          <label
            htmlFor="confirmPassword"
            className="block text-charcoal font-semibold mb-1"
          >
            {t("confirmPassword")}:
          </label>
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-persianGreen"
          />
          {errorMessages.confirmPassword && (
            <p className="text-red-500">{errorMessages.confirmPassword}</p>
          )}
        </div>

        <TaskSelection
          selectedTasks={selectedTasks}
          setSelectedTasks={setSelectedTasks}
        />
        {errorMessages.tasks && (
          <p className="text-red-500">{errorMessages.tasks}</p>
        )}

        <button
          type="submit"
          className="w-full bg-persianGreen text-white font-semibold py-2 rounded-md hover:bg-green-700 transition duration-200"
        >
          {t("register")}
        </button>
      </form>
    </div>
  );
};

export default UserRegister;
