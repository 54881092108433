import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { isValid } from "date-fns";
import { useTranslation } from "react-i18next";
import config from "../config";
import {
  formatDateISO,
  formatTime,
  formatDateForLink,
} from "../utils/dateUtils";

const UserViewSchedule = () => {
  const { t } = useTranslation();
  const [schedules, setSchedules] = useState([]);
  const [error, setError] = useState("");

  const fetchUserSchedule = async () => {
    const userId = localStorage.getItem("userId");

    if (!userId) {
      setError("User ID not found.");
      return;
    }

    try {
      const response = await fetch(
        `${config.API_URL}/user/view/schedule/${userId}`
      );

      const data = await response.json();

      if (data.success) {
        console.log(data.schedules); // Log the raw data

        // Ensure correct date formatting
        setSchedules(
          data.schedules.map((schedule) => {
            try {
              // Log the raw scheduled_date
              console.log("Raw scheduled_date:", schedule.scheduled_date);

              // Convert scheduled_date to Date object
              const scheduleDateTime = new Date(schedule.scheduled_date);
              console.log("Parsed Date object:", scheduleDateTime); // Log the Date object

              if (!isValid(scheduleDateTime)) {
                throw new Error(`Invalid DateTime: ${schedule.scheduled_date}`);
              }

              return {
                ...schedule,
                schedule_datetime: scheduleDateTime, // Set Date object
                formatted_date: formatDateISO(scheduleDateTime), // 'yyyy-MM-dd'
                formatted_time: formatTime(scheduleDateTime), // 'HH:mm'
              };
            } catch (error) {
              console.error(error.message);
              return {
                ...schedule,
                schedule_datetime: null,
                formatted_date: "Invalid Date",
                formatted_time: "Invalid Time",
              };
            }
          })
        );
      } else {
        setError(data.message || "Failed to load schedule.");
      }
    } catch (err) {
      console.error("Error fetching user schedule:", err);
      setError("An error occurred while fetching the schedule.");
    }
  };

  useEffect(() => {
    fetchUserSchedule();
  }, []);

  console.log(schedules); // Log the schedules data

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-xl font-bold mb-4">Your Schedule</h2>
      {error && <p className="text-red-500">{error}</p>}
      {schedules.length > 0 ? (
        <table className="table-auto w-full border-collapse border border-gray-300">
          <thead>
            <tr>
              <th className="border px-4 py-2">Date</th>
              <th className="border px-4 py-2">Time</th>
              <th className="border px-4 py-2">Task</th>
            </tr>
          </thead>
          <tbody>
            {schedules.map((schedule) =>
              schedule.schedule_datetime ? (
                <tr key={schedule.scheduled_date}>
                  <td className="border px-4 py-2">
                    <Link
                      to={`/user/schedule/view/${formatDateForLink(
                        schedule.schedule_datetime
                      )}`}
                      className="text-blue-500 hover:underline"
                    >
                      {schedule.formatted_date}
                    </Link>
                  </td>
                  <td className="border px-4 py-2">
                    {schedule.formatted_time}
                  </td>
                  <td className="border px-4 py-2">{t(schedule.task)}</td>
                </tr>
              ) : null
            )}
          </tbody>
        </table>
      ) : (
        !error && <p>No schedules found.</p>
      )}
    </div>
  );
};

export default UserViewSchedule;
