// src/components/AdminNavbar.js
import React from "react";
import { Link } from "react-router-dom";
import {
  FaHome,
  FaUserClock,
  FaCalendarAlt,
  FaClipboardCheck,
} from "react-icons/fa"; // Import icons
import LogoutButton from "./LogoutButton"; // Import LogoutButton component

const AdminBottomNavbar = ({ handleLogout }) => {
  const logout = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    if (handleLogout) {
      handleLogout(); // Call the parent handleLogout if provided
    }
  };

  return (
    <nav className="fixed bottom-0 left-0 right-0 bg-charcoal p-6 h-20 shadow-inner">
      {" "}
      {/* Increased padding and added height */}
      <ul className="flex justify-around items-center h-full">
        {" "}
        {/* Center icons vertically */}
        <li>
          <Link to="/" className="text-white">
            <FaHome size={24} /> {/* Home icon */}
          </Link>
        </li>
        <li>
          <Link to="/admin/checkedin-users" className="text-white">
            <FaClipboardCheck size={24} /> {/* Dashboard icon */}
          </Link>
        </li>
        <li>
          <Link to="/admin/confirm-user-hours" className="text-white">
            <FaUserClock size={24} /> {/* Confirm hours icon */}
          </Link>
        </li>
        <li>
          <Link to="/admin/make-schedule" className="text-white">
            <FaCalendarAlt size={24} /> {/* Make schedule icon */}
          </Link>
        </li>
        {/* Render LogoutButton component, passing the logout function */}
        <li>
          <LogoutButton handleLogout={logout} />
        </li>
      </ul>
    </nav>
  );
};

export default AdminBottomNavbar;
