// Filename: src/utils/dateUtils.js

import { format, isValid } from "date-fns";
import { enUS, es, fr, nl } from "date-fns/locale"; // Import the locales you need

const locales = {
  en: enUS,
  es: es,
  fr: fr,
  nl: nl,
  // Add more locales as needed
};

// Format the date to 'yyyy-MM-dd' in a specific locale
export const formatDateISO = (date, locale = "en") => {
  const parsedDate = new Date(date);
  const currentLocale = locales[locale] || enUS; // Default to enUS if the locale is not found

  if (isValid(parsedDate)) {
    return format(parsedDate, "yyyy-MM-dd", { locale: currentLocale });
  } else {
    return "Invalid Date"; // Return a default value in case of an invalid date
  }
};

// Format the time to 'HH:mm' in a specific locale
export const formatTime = (datetime, locale = "en") => {
  const parsedDate = new Date(datetime);
  const currentLocale = locales[locale] || enUS; // Default to enUS if the locale is not found

  if (isValid(parsedDate)) {
    return format(parsedDate, "HH:mm", { locale: currentLocale }); // Format to 'HH:mm'
  } else {
    return "Invalid Time"; // Return a default value in case of an invalid time
  }
};

// Format the date for use in links in a specific locale
export const formatDateForLink = (datetime, locale = "en") => {
  const currentLocale = locales[locale] || enUS; // Default to enUS if the locale is not found
  return format(new Date(datetime), "yyyy-MM-dd", { locale: currentLocale });
};
