// src/components/LogoutButton.js

import React from "react";
import { FaSignOutAlt } from "react-icons/fa";

const LogoutButton = ({ handleLogout }) => {
  return (
    <button
      onClick={handleLogout}
      className="flex font-semibold items-center rounded-lg px-4 py-2 text-gray-800 hover:bg-gray-100"
    >
      <FaSignOutAlt size={28} className="mr-2" />
    </button>
  );
};

export default LogoutButton;
