// Filename: src/components/TopNavbar.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaHome } from "react-icons/fa";

const UserBottomNavbar = ({ handleLogout, isLoggedIn }) => {
  const { t } = useTranslation();
  const [activeButton, setActiveButton] = useState("/");
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);

  const logout = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    handleLogout();
    setActiveButton(""); // Clear active button on logout
  };

  const handleMenuClick = (path) => {
    setActiveButton(path);
  };

  return (
    <nav className="top-0 left-0 right-0 bg-orange-600 shadow-lg z-50">
      <div className="max-w-7xl mx-auto px-4 py-2">
        <div className="flex justify-between items-center">
          {/* Home Button */}
          <Link
            to="/"
            className={`text-white rounded-lg ${
              activeButton === "/" ? "bg-gray-700 p-2" : "p-2"
            }`} // Larger background when active
            onClick={() => handleMenuClick("/")}
          >
            <FaHome size={20} /> Home
          </Link>

          <div className="flex space-x-4">
            {/* Worked Hours Button */}
            {isLoggedIn && (
              <Link
                to="/user/view-worked-hours"
                className={`text-white rounded-lg ${
                  activeButton === "/user/view-worked-hours"
                    ? "bg-gray-700 p-2"
                    : "p-2"
                }`} // Larger background when active
                onClick={() => handleMenuClick("/user/view-worked-hours")}
              >
                Worked Hours
              </Link>
            )}

            {/* Availability Button */}
            {isLoggedIn && (
              <Link
                to="/user/view-schedule"
                className={`text-white rounded-lg ${
                  activeButton === "/user/view-schedule"
                    ? "bg-gray-700 p-2"
                    : "p-2"
                }`} // Larger background when active
                onClick={() => handleMenuClick("/user/view-schedule")}
              >
                Availability
              </Link>
            )}

            {/* Profile Button with Dropdown */}
            {isLoggedIn && (
              <div className="relative">
                <button
                  onClick={() => setIsProfileDropdownOpen((prev) => !prev)}
                  className={`text-white rounded-lg ${
                    activeButton.includes("/user/edit-profile") ||
                    activeButton.includes("/user/login")
                      ? "bg-gray-700 p-2"
                      : "p-2"
                  }`}
                >
                  Profile
                </button>
                {isProfileDropdownOpen && (
                  <div className="absolute right-0 bg-white shadow-lg rounded-md mt-1">
                    <Link
                      to="/user/edit-profile"
                      className="block px-4 py-2 text-black hover:bg-gray-200"
                      onClick={() => {
                        handleMenuClick("/user/edit-profile");
                        setIsProfileDropdownOpen(false);
                      }}
                    >
                      Edit Profile
                    </Link>
                    <div
                      className="block px-4 py-2 text-black hover:bg-gray-200 cursor-pointer"
                      onClick={logout}
                    >
                      Logout
                    </div>
                  </div>
                )}
              </div>
            )}

            {/* Login Button */}
            {!isLoggedIn && (
              <Link
                to="/user/login"
                className={`text-white rounded-lg ${
                  activeButton === "/user/login" ? "bg-gray-700 p-2" : "p-2"
                }`} // Larger background when active
                onClick={() => handleMenuClick("/user/login")}
              >
                Login
              </Link>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default UserBottomNavbar;
