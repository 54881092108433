// src/pages/PageNotFound.js
import React from "react";

const PageNotFound = () => {
  return (
    <div className="flex items-center justify-center h-screen text-center">
      <h1 className="text-4xl font-bold text-charcoal">404</h1>
      <p className="text-lg mt-12 text-gray-600">Page Not Found</p>
    </div>
  );
};

export default PageNotFound;
