// src/components/TaskButtons.js
import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBeer,
  faUtensils,
  faGlassCheers,
  faUsers,
} from "@fortawesome/free-solid-svg-icons"; // Import your icons

const TaskButtons = ({ tasks, handleTaskClick, getTaskColor }) => {
  const { t } = useTranslation();

  // Function to map icon names to actual Font Awesome icons
  const getIcon = (iconName) => {
    switch (iconName) {
      case "faBeer":
        return <FontAwesomeIcon icon={faBeer} />;
      case "faUtensils":
        return <FontAwesomeIcon icon={faUtensils} />;
      case "faGlassCheers":
        return <FontAwesomeIcon icon={faGlassCheers} />;
      case "faUsers":
        return <FontAwesomeIcon icon={faUsers} />;
      default:
        return null; // Handle the case where no icon is found
    }
  };

  return (
    <div className="flex">
      {tasks.map((task) => (
        <button
          key={task.id} // Use the task ID as the key
          onClick={() => handleTaskClick(task.name)}
          className={`m-2 cursor-pointer p-0 rounded ${task.color} w-40 h-12 flex items-center justify-center`}
        >
          {getIcon(task.icon)} {/* Render the icon */}
          <span className="ml-2">{t(task.name)}</span>{" "}
          {/* Task name with margin */}
        </button>
      ))}
    </div>
  );
};

export default TaskButtons;
