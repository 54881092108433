import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation("homepage");
  const isLoggedIn = localStorage.getItem("userId"); // Check if the user is logged in

  return (
    <div className="flex flex-col justify-center items-center h-screen text-black">
      {/* Welcome Section */}
      <div className="text-center mb-2">
        <h1 className="text-3xl mb-2 mt-0">{t("welcomeMessage")}</h1>
      </div>

      {/* Call to Action Section */}
      <div className="mt-4 flex justify-center gap-4">
        {!isLoggedIn && (
          <>
            <Link
              to="/user/login"
              className="bg-persianGreen w-40 text-white font-bold py-2 rounded-lg shadow hover:bg-saffron transition duration-200 text-center"
            >
              {t("login")}
            </Link>
            <Link
              to="/user/register"
              className="bg-persianGreen w-40 text-white font-bold py-2 rounded-lg shadow hover:bg-saffron transition duration-200 text-center"
            >
              {t("register")}
            </Link>
          </>
        )}
      </div>

      {/* Beta Message */}
      <div className="mt-8 bg-sandyBrown p-4 rounded-lg text-white shadow-lg max-w-lg text-center">
        <p className="text-lg">{t("trackWorkHoursDescription")}</p>
      </div>
    </div>
  );
};

export default Home;
