// Filename: src/components/UserViewWorkedHours.js
import React, { useEffect, useState } from "react";
import config from "../config";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../utils/formatNumber.js";

const UserViewWorkedHours = () => {
  const userId = localStorage.getItem("userId");
  const username = localStorage.getItem("userName");
  const [workedHours, setWorkedHours] = useState(null);
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchWorkedHours = async () => {
      try {
        const response = await fetch(
          `${config.API_URL}/user/get-worked-hours/${userId}`
        );
        const data = await response.json();

        if (!data.success) {
          setError(data.message || "Failed to fetch worked hours");
        } else {
          setWorkedHours(data.workingHours);
        }
      } catch (err) {
        console.error("Error fetching worked hours:", err);
        setError("An error occurred while fetching worked hours.");
      }
    };

    fetchWorkedHours();
  }, [userId]);

  const calculateWorkedHours = (startTime, endTime) => {
    if (!startTime || !endTime) return 0;
    const start = new Date(startTime);
    const end = new Date(endTime);
    const hours = (end - start) / (1000 * 60 * 60);
    return hours;
  };

  const formatDate = (date) => {
    const options = { month: "short", day: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  const totalWorkedHours = workedHours
    ? workedHours.entries.reduce(
        (total, entry) =>
          total + calculateWorkedHours(entry.start_time, entry.end_time),
        0
      )
    : 0;

  if (error) {
    return (
      <div className="max-w-4xl mx-auto mt-10 p-6 bg-white shadow-lg rounded-lg">
        <p className="text-red-500 text-center">{error}</p>
      </div>
    );
  }

  if (!workedHours) {
    return (
      <div className="max-w-4xl mx-auto mt-10 p-6 bg-white shadow-lg rounded-lg">
        <p className="text-center">Loading...</p>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto mt-10 p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold mb-6 text-center">
        {username ? `${username}'s worked Hours` : "No Username Available"}
      </h2>
      <table className="w-full border-collapse">
        <thead>
          <tr>
            <th className="border p-2">{t("date")}</th>
            <th className="border p-2">{t("start_time")}</th>
            <th className="border p-2">{t("end_time")}</th>
            <th className="border p-2">{t("total_worked_hours")}</th>
            <th className="border p-2">{t("confirmed")}</th>
          </tr>
        </thead>
        <tbody>
          {workedHours.entries.length > 0 ? (
            workedHours.entries.map((entry, index) => (
              <tr
                key={index}
                className={!entry.confirmed_by ? "bg-red-100" : ""}
              >
                <td className="border p-2">
                  {entry.date ? formatDate(entry.date) : "-"}
                </td>
                <td className="border p-2">
                  {entry.start_time
                    ? new Date(entry.start_time).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })
                    : "-"}
                </td>
                <td className="border p-2">
                  {entry.end_time
                    ? new Date(entry.end_time).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })
                    : "-"}
                </td>
                <td className="border p-2">
                  {formatNumber(
                    calculateWorkedHours(entry.start_time, entry.end_time)
                  )}{" "}
                </td>
                <td className="border p-2">{entry.confirmed_by}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="border p-2 text-center">
                No entries found for this user.
              </td>
            </tr>
          )}
        </tbody>
        {workedHours.entries.length > 0 && (
          <tfoot>
            <tr>
              <td colSpan="3" className="border p-2 text-right font-bold">
                Total Worked Hours:
              </td>
              <td className="border p-2 font-bold">
                {formatNumber(totalWorkedHours)}
              </td>
              <td></td>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
};

export default UserViewWorkedHours;
