// Filename: src/pages/Login.js
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import config from "../config";
import { useTranslation } from "react-i18next";
import { TenantContext } from "../contexts/TenantProvider";
// import TenantRegister from "./tenant/TenantRegister";

const Login = ({ onLogin }) => {
  const { t } = useTranslation();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const tenant = useContext(TenantContext); // Access tenant from context

  // Check localStorage for token and navigate if already logged in
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      // navigate("/"); // Redirect to home/dashboard if already logged in
    }
  }, [navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!tenant?.tenantId) {
      setError(t("login.missingTenantId"));
      return;
    }
    const tenantId = tenant.tenantId;
    fetch(`${config.API_URL}/user/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ username, password, tenantId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // Store JWT in localStorage
          localStorage.setItem("token", data.token);
          localStorage.setItem("userId", data.userId);
          localStorage.setItem("userName", data.userName);
          localStorage.setItem("userRole", data.role);
          localStorage.setItem("tenantId", data.tenantId);
          //localStorage.setItem("subDomain", data.subDomain);

          onLogin(); // Update global state or context
          navigate("/"); // Redirect to home/dashboard
        } else {
          setError(t("login.invalidCredentials"));
        }
      })
      .catch((error) => {
        console.error("Login error:", error);
        setError(t("login.serverError"));
      });
  };

  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-bold mb-4 text-center text-charcoal">
        {t("login")}
      </h2>
      {error && <p className="text-red-500 mb-4 text-center">{error}</p>}

      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label
            htmlFor="username"
            className="block text-charcoal font-semibold mb-1"
          >
            {t("username")}
          </label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-persianGreen"
          />
        </div>

        <div>
          <label
            htmlFor="password"
            className="block text-charcoal font-semibold mb-1"
          >
            {t("password")}
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-persianGreen"
          />
        </div>

        <button
          type="submit"
          className="w-full bg-persianGreen text-white py-2 rounded-md hover:bg-charcoal focus:outline-none focus:ring-2 focus:ring-persianGreen"
        >
          {t("login")}
        </button>
      </form>
    </div>
  );
};

export default Login;
