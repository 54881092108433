import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import config from "../config";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { parseISO, format } from "date-fns";
import { toZonedTime } from "date-fns-tz"; // Using toZonedTime instead of toZonedTime
//import { toZonedTime } from "date-fns-tz"; // Corrected import

const SubmitWorkedHours = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [startHour, setStartHour] = useState("16");
  const [startMinute, setStartMinute] = useState("00");
  const [endHour, setEndHour] = useState("17");
  const [endMinute, setEndMinute] = useState("00");
  const [error, setError] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [workedHours, setWorkedHours] = useState({
    startTime: "",
    endTime: "",
  });

  // Fetch start time from the database

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      fetch(
        `${config.API_URL}/user/submit-hours/get-start-time?userId=${userId}`
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.success && data.startTime) {
            const startTimeUTC = parseISO(data.startTime); // Parse as UTC
            const timeZone = "Europe/Amsterdam"; // Adjust to your timezone
            const zonedTime = toZonedTime(startTimeUTC, timeZone); // Convert UTC to timezone

            // Debugging: Log times
            console.log("Fetched UTC Time:", data.startTime);
            console.log("Converted Timezone Time:", zonedTime);

            // Format the time for UI
            const formattedHour = format(zonedTime, "HH");
            const formattedMinute = format(zonedTime, "mm");
            setStartHour(formattedHour);
            setStartMinute(formattedMinute);
          } else {
            setError(t("error_fetching_start_time"));
          }
        })
        .catch((error) => {
          console.error("Error fetching start time:", error);
          setError(t("error_occurred"));
        });
    }
  }, [t]);

  // Round time to nearest 15 minutes
  const roundToNearest15Minutes = (date) => {
    const minutes = Math.round(date.getMinutes() / 15) * 15;
    date.setMinutes(minutes);
    date.setSeconds(0);
    return date;
  };

  useEffect(() => {
    const now = new Date();
    const roundedEndTime = roundToNearest15Minutes(new Date(now));
    setEndHour(String(roundedEndTime.getHours()).padStart(2, "0"));
    setEndMinute(String(roundedEndTime.getMinutes()).padStart(2, "0"));
  }, []);

  // Generate hour options (00-23)
  const generateHourOptions = () => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      const time = String(hour).padStart(2, "0");
      options.push(
        <option key={time} value={time}>
          {time}
        </option>
      );
    }
    return options;
  };

  // Generate minute options (00, 15, 30, 45)
  const generateMinuteOptions = () => {
    const options = [];
    for (let minute = 0; minute < 60; minute += 15) {
      const time = String(minute).padStart(2, "0");
      options.push(
        <option key={time} value={time}>
          {time}
        </option>
      );
    }
    return options;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const userId = localStorage.getItem("userId");
    if (!userId) {
      setError(t("user_id_not_found"));
      return;
    }

    const startTime = `${startHour}:${startMinute}`;
    const endTime = `${endHour}:${endMinute}`;

    // Show confirmation
    setWorkedHours({ startTime, endTime });
    setShowConfirmation(true);
  };

  const confirmSubmission = () => {
    const userId = localStorage.getItem("userId");
    const { startTime, endTime } = workedHours;

    const startDateTime = `${startDate}T${startTime}:00`;
    const endDateTime = `${startDate}T${endTime}:00`;

    fetch(`${config.API_URL}/user/submit-worked-hours`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        userId,
        startTime: startDateTime,
        endTime: endDateTime,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          navigate("/user/view-worked-hours");
        } else {
          setError(
            t("submit_failure", { message: data.message || "Unknown error" })
          );
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setError(t("error_occurred"));
      });
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  // Handle changing date
  const handleDateChange = (direction) => {
    const currentDate = new Date(startDate);
    if (direction === "prev") {
      currentDate.setDate(currentDate.getDate() - 1);
    } else if (direction === "next") {
      const today = new Date();
      if (currentDate.toDateString() === today.toDateString()) return; // Prevent selecting tomorrow
      currentDate.setDate(currentDate.getDate() + 1);
    }
    setStartDate(currentDate.toISOString().split("T")[0]);
  };

  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-bold mb-4 text-center text-charcoal">
        {t("submit_hours")}
      </h2>
      {error && <p className="text-red-500 mb-4 text-center">{error}</p>}
      {!showConfirmation ? (
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="flex items-center space-x-2">
            <button
              type="button"
              onClick={() => handleDateChange("prev")}
              className="text-charcoal hover:text-persianGreen"
            >
              <FaArrowLeft />
            </button>
            <div className="flex-grow">
              <label
                htmlFor="startDate"
                className="block text-charcoal font-semibold mb-1"
              >
                {t("date")}
              </label>
              <input
                type="date"
                id="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-persianGreen"
                required
                max={new Date().toISOString().split("T")[0]}
              />
            </div>
            <button
              type="button"
              onClick={() => handleDateChange("next")}
              className="text-charcoal hover:text-persianGreen"
            >
              <FaArrowRight />
            </button>
          </div>
          <div>
            <label
              htmlFor="startTime"
              className="block text-charcoal font-semibold mb-1"
            >
              {t("start_time")}
            </label>
            <div className="flex space-x-4">
              <select
                id="startHour"
                value={startHour}
                onChange={(e) => setStartHour(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              >
                {generateHourOptions()}
              </select>
              <select
                id="startMinute"
                value={startMinute}
                onChange={(e) => setStartMinute(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              >
                {generateMinuteOptions()}
              </select>
            </div>
          </div>
          <div>
            <label
              htmlFor="endTime"
              className="block text-charcoal font-semibold mb-1"
            >
              {t("end_time")}
            </label>
            <div className="flex space-x-4">
              <select
                id="endHour"
                value={endHour}
                onChange={(e) => setEndHour(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              >
                {generateHourOptions()}
              </select>
              <select
                id="endMinute"
                value={endMinute}
                onChange={(e) => setEndMinute(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              >
                {generateMinuteOptions()}
              </select>
            </div>
          </div>
          <button
            type="submit"
            className="w-full px-4 py-2 bg-persianGreen text-white rounded-md hover:bg-persianGreenDark"
          >
            {t("submit")}
          </button>
        </form>
      ) : (
        <div className="space-y-4">
          <h3 className="text-lg font-semibold text-center text-charcoal">
            {t("confirm_submission")}
          </h3>
          <p className="text-center">
            {t("start_time")}: {workedHours.startTime}
          </p>
          <p className="text-center">
            {t("end_time")}: {workedHours.endTime}
          </p>
          <div className="flex justify-center space-x-4">
            <button
              onClick={confirmSubmission}
              className="px-4 py-2 bg-persianGreen text-white rounded-md hover:bg-persianGreenDark"
            >
              {t("confirm")}
            </button>
            <button
              onClick={handleCancel}
              className="px-4 py-2 bg-gray-300 text-white rounded-md hover:bg-gray-400"
            >
              {t("cancel")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SubmitWorkedHours;
