const config = {
  development: {
    API_URL: "http://localhost:5000/api",
  },
  production: {
    API_URL: "https://thechefsclock.com/api", // No port in production
  },
};

const ENV = process.env.NODE_ENV || "development"; // Check current environment

export default config[ENV];
