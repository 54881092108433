// src/pages/UserEditProfile.js
import React, { useState, useEffect, useContext } from "react";
import config from "../config"; // Adjust path as needed
import { useTranslation } from "react-i18next";
import TaskSelection from "../components/TaskSelection"; // Import the TaskSelection component
import { TenantContext } from "../contexts/TenantProvider";

const UserEditProfile = () => {
  const tenant = useContext(TenantContext);
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    employeeNr: "",
  });
  const [selectedTasks, setSelectedTasks] = useState([]); // Track selected tasks
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(""); // Track success messages

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    fetch(`${config.API_URL}/user/get-user-data`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ userId }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to load user data");
        }
        return response.json();
      })
      .then((data) => {
        setFormData({
          name: data.username,
          email: data.email || "",
          employeeNr: data.employee_nr,
        });
        const uniqueTasks = [...new Set(data.task ? data.task.split(",") : [])];
        setSelectedTasks(uniqueTasks);
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const userId = localStorage.getItem("userId");

    fetch(`${config.API_URL}/user/update`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: formData.name,
        email: formData.email,
        employee_nr: formData.employeeNr,
        role: "employee",
        tasks: selectedTasks,
        userId,
        tenantId: tenant.tenantId,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((error) => {
            throw new Error(error.message);
          });
        }
        return response.json();
      })
      .then(() => {
        setErrorMessage(""); // Clear any previous error messages
        setSuccessMessage(t("Profile updated successfully")); // Set success message
        setTimeout(() => setSuccessMessage(""), 5000); // Clear success message after 5 seconds
      })
      .catch((error) => {
        setSuccessMessage(""); // Clear any previous success messages
        setErrorMessage(error.message); // Display error message
      });
  };

  return (
    <div className="max-w-md mx-auto mt-6 p-4 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-bold mb-4 text-center text-charcoal">
        {t("edit_profile")}
      </h2>

      {/* Display Error or Success Message */}
      {errorMessage && (
        <p className="text-burnt-sienna font-semibold mb-4">{errorMessage}</p>
      )}
      {successMessage && (
        <p className="text-persianGreen font-semibold mb-4">{successMessage}</p>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label
            className="block text-charcoal font-semibold mb-1"
            htmlFor="name"
          >
            {t("username")}
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-persianGreen"
            required
          />
        </div>
        <div>
          <label
            className="block text-charcoal font-semibold mb-1"
            htmlFor="email"
          >
            {t("email")}
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-persianGreen"
            required
          />
        </div>
        <div>
          <label
            className="block text-charcoal font-semibold mb-1"
            htmlFor="employeeNr"
          >
            {t("employee_nr")}
          </label>
          <input
            type="text"
            name="employeeNr"
            value={formData.employeeNr}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-persianGreen"
            required
          />
        </div>

        <TaskSelection
          selectedTasks={selectedTasks}
          setSelectedTasks={setSelectedTasks}
        />

        <button
          type="submit"
          className="w-full bg-persianGreen text-white font-semibold py-2 rounded-md hover:bg-green-700 transition duration-200"
        >
          {t("save_changes")}
        </button>
      </form>
    </div>
  );
};

export default UserEditProfile;
