// src/pages/EmployeesList.js
import React, { useState, useEffect } from "react";
import config from "../../config";
import { formatNumber } from "../../utils/formatNumber"; // Import formatNumber

const EmployeesList = () => {
  const [employees, setEmployees] = useState([]);
  const [workedHours, setWorkedHours] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch all employees first
    const tenantId = localStorage.getItem("tenantId");
    fetch(`${config.API_URL}/admin/get/employees/${tenantId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setEmployees(data.users);
          // Fetch worked hours for each employee
          data.users.forEach((user) => {
            fetchWorkedHours(user.id);
          });
        } else {
          setError("Failed to load employees");
        }
      })
      .catch((error) => {
        console.error("Error fetching employees:", error);
        setError("An error occurred while fetching data.");
      });
  }, []);

  const fetchWorkedHours = async (userId) => {
    try {
      const response = await fetch(
        `${config.API_URL}/user/get-worked-hours/${userId}`
      );
      const data = await response.json();
      if (data.success) {
        setWorkedHours((prev) => ({
          ...prev,
          [userId]: data.workingHours.entries, // Store the entries under the userId key
        }));
      } else {
        console.error(
          `Failed to fetch worked hours for user ${userId}: ${data.message}`
        );
      }
    } catch (error) {
      console.error("Error fetching worked hours:", error);
    }
  };

  const handleChangeRole = async (userId, currentRole) => {
    const newRole = currentRole === "employee" ? "manager" : "employee"; // Toggle role
    try {
      const response = await fetch(`${config.API_URL}/admin/update/role`, {
        // Updated URL
        method: "PUT", // Changed to PUT
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId, role: newRole }), // Updated payload
      });
      const data = await response.json();
      if (data.success) {
        // Update the employees state with the new role
        setEmployees((prevEmployees) =>
          prevEmployees.map((employee) =>
            employee.id === userId ? { ...employee, role: newRole } : employee
          )
        );
      } else {
        console.error(
          `Failed to change role for user ${userId}: ${data.message}`
        );
      }
    } catch (error) {
      console.error("Error changing role:", error);
    }
  };
  // Function to calculate total worked hours for an employee
  const calculateTotalWorkedHours = (entries) => {
    if (!entries) return 0;
    const totalMinutes = entries.reduce((acc, entry) => {
      const start = new Date(entry.start_time);
      const end = new Date(entry.end_time);
      return acc + (end - start) / (1000 * 60); // Convert milliseconds to minutes
    }, 0);
    return (totalMinutes / 60).toFixed(2); // Convert to hours
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Employees List</h2>
      {error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <table className="min-w-full bg-white border">
          <thead>
            <tr>
              <th className="px-3 py-2 border">ID</th>
              <th className="px-3 py-2 border">Username</th>
              <th className="px-3 py-2 border">Role</th>
              <th className="px-3 py-2 border">Total</th>
              <th className="px-3 py-2 border">Actions</th>
            </tr>
          </thead>
          <tbody>
            {employees.map((employee) => (
              <tr key={employee.id}>
                <td className="px-3 py-2 border">{employee.id}</td>
                <td className="px-3 py-2 border">{employee.username}</td>
                <td className="px-3 py-2 border">{employee.role}</td>
                <td className="px-3 py-2 border">
                  {formatNumber(
                    calculateTotalWorkedHours(workedHours[employee.id])
                  )}
                </td>
                <td className="px-3 py-2 border">
                  {employee.role !== "admin" && (
                    <button
                      className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-700"
                      onClick={() =>
                        handleChangeRole(employee.id, employee.role)
                      }
                    >
                      {employee.role === "employee" ? "Manager" : "Employee"}
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default EmployeesList;
