import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBeer,
  faUtensils,
  faGlassCheers,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

const TimeSlots = ({
  todaysSchedule = [], // Default to an empty array if not provided
  handleDragStart,
  handleRemoveFromTimeSlot,
  handleDrop,
  getTaskColor,
  availableUsersToday, // To display available users in the drop zone
}) => {
  const timeSlots = [];
  const startTime = 15; // 3:00 PM
  const endTime = 19; // 7:00 PM

  // Task icon mapping using an object
  const taskIcons = {
    food_runner: faBeer,
    waiter: faUtensils,
    bartender: faGlassCheers,
    manager: faUsers,
  };

  console.log("Today's Schedule:", todaysSchedule);

  // Function to get the icon for a task
  const getTaskIcon = (taskIcon) => {
    const icon = taskIcons[taskIcon];
    return icon ? <FontAwesomeIcon icon={icon} /> : null;
  };

  // Function to format time from a datetime string or just a time string
  const formatTime = (timeString) => {
    if (!timeString || typeof timeString !== "string") {
      console.warn("Invalid time string:", timeString);
      return ""; // Return an empty string if the timeString is invalid
    }

    if (timeString.includes("T")) {
      // Handle full datetime strings
      const date = new Date(timeString);
      if (isNaN(date.getTime())) {
        console.warn("Invalid datetime format:", timeString);
        return ""; // Return an empty string if the datetime is invalid
      }

      const hours = date.getHours(); // Get hours in 24-hour format
      const minutes = date.getMinutes();
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`; // Return formatted time
    } else if (timeString.match(/^\d{2}:\d{2}$/)) {
      // Handle time-only strings (e.g., '19:15')
      return timeString;
    }

    console.warn("Unrecognized time format:", timeString);
    return ""; // Return an empty string for unsupported formats
  };

  // Create time slots from start to end time
  for (let hour = startTime; hour <= endTime; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const time = `${hour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")}`;

      timeSlots.push(
        <div
          key={`timeslot-${time}`} // Unique key for each time slot
          className="border border-gray-400 p-4 flex justify-between w-full"
          onDrop={(e) => {
            e.preventDefault(); // Prevent default behavior
            handleDrop(time)(e); // Call handleDrop with the time
          }} // Handle drop event
          onDragOver={(e) => e.preventDefault()} // Allow dropping
        >
          <span className="font-bold">{formatTime(time)}</span>{" "}
          {/* Display hour as bold */}
          <div className="flex-grow overflow-hidden">
            <div className="flex flex-wrap">
              {todaysSchedule.length > 0 &&
                todaysSchedule // Check if todaysSchedule is an array and has items
                  .filter((scheduledUser) => {
                    // Ensure valid scheduled_date before formatting
                    if (!scheduledUser.schedule_time) return false; // Skip if no schedule_time
                    return (
                      formatTime(scheduledUser.schedule_time) ===
                      formatTime(time) // Compare formatted time with the timeslot
                    );
                  })
                  .map((scheduledUser, index) => {
                    const userId = scheduledUser.id || `unknown-${index}`; // Fallback key if user ID is undefined

                    return (
                      <button
                        key={`user-${userId}-${time}`} // Unique key for each user
                        className={`mr-2 cursor-pointer p-2 rounded flex items-center justify-center w-24 h-8 ${
                          getTaskColor(scheduledUser.task) || "bg-gray-300"
                        }`}
                        onDragStart={(e) => {
                          e.dataTransfer.setData("userId", userId); // Set user ID in drag event
                          handleDragStart(userId)(e); // Pass the event and user ID
                        }}
                        onClick={() => handleRemoveFromTimeSlot(scheduledUser)} // Handle user removal from slot
                        draggable // Make the button draggable
                      >
                        {getTaskIcon(scheduledUser.taskIcon)}{" "}
                        <span className="ml-2">
                          {scheduledUser.username || `User ID: ${userId}`}
                        </span>
                      </button>
                    );
                  })}
            </div>
          </div>
        </div>
      );
    }
  }

  return <div className="flex flex-col">{timeSlots}</div>; // Render all time slots
};

export default TimeSlots;
