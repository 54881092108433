// Filename: src/pages/admin/AdminDashboard.js
import React from "react";
import { Outlet } from "react-router-dom";

const AdminDashboard = () => {
  return (
    <div>
      <h1>Admin Dashboard</h1>
      {/* Render child routes here */}
      <Outlet />
    </div>
  );
};

export default AdminDashboard;
