// Filename: src/pages/UserSetAvailability.js

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import config from "../config"; // Import config

const UserSetAvailability = () => {
  const { t } = useTranslation();
  const userId = localStorage.getItem("userId");
  const [availability, setAvailability] = useState([]);
  const [comment, setComment] = useState("");
  const [commentDate, setCommentDate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messages, setMessages] = useState([]); // State to hold messages

  useEffect(() => {
    const fetchExistingAvailability = async () => {
      try {
        const response = await fetch(
          `${config.API_URL}/user/get_existing_availability/${userId}`
        );
        const data = await response.json();
        if (data.success) {
          const existingAvailabilityData = data.availability;
          const today = new Date();
          const nextThreeWeeks = [];

          // Iterate through the next 21 days to build the availability array
          for (let i = 0; i < 21; i++) {
            const date = new Date();
            date.setDate(today.getDate() + i);

            // Format the date to match the existing data format
            const formattedDate = date.toISOString().split("T")[0];

            // Check if there's existing availability for the formatted date
            const matchingAvailability = existingAvailabilityData.find(
              (entry) => entry.available_date.split("T")[0] === formattedDate
            );

            nextThreeWeeks.push({
              date: formattedDate,
              available: !!matchingAvailability, // true if available
              comment: matchingAvailability ? matchingAvailability.comment : "",
            });
          }

          setAvailability(nextThreeWeeks);
        }
      } catch (error) {
        console.error("Error fetching existing availability:", error);
      }
    };

    fetchExistingAvailability();
  }, [userId]);

  const handleAvailabilityChange = (index, field, value) => {
    const updatedAvailability = [...availability];
    updatedAvailability[index][field] = value;
    setAvailability(updatedAvailability);
  };

  const openCommentModal = (date) => {
    const selectedEntry = availability.find((entry) => entry.date === date);
    setCommentDate(date);
    setComment(selectedEntry ? selectedEntry.comment : "");
    setIsModalOpen(true);
  };

  const handleSubmitComment = () => {
    if (commentDate) {
      const updatedAvailability = availability.map((entry) => {
        if (entry.date === commentDate) {
          return { ...entry, comment };
        }
        return entry;
      });
      setAvailability(updatedAvailability);
      closeCommentModal();
    }
  };

  const closeCommentModal = () => {
    setIsModalOpen(false);
    setComment(""); // Clear the comment input
    setCommentDate(null); // Reset comment date
  };

  const handleSubmit = () => {
    const newAvailability = availability.filter((entry) => entry.available);

    setMessages([]); // Clear previous messages

    if (newAvailability.length === 0) {
      setMessages((prev) => [...prev, t("noNewChanges")]); // Add no new changes message
      return;
    }

    fetch(`${config.API_URL}/user/set_availability`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ userId, availability: newAvailability }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setMessages((prev) => [...prev, t("availabilitySaved")]); // Add success message
        } else {
          setMessages((prev) => [...prev, t("errorSavingAvailability")]); // Add error message
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setMessages((prev) => [...prev, t("errorSavingAvailability")]); // Add error message
      });
    console.log(availability);
  };

  // Helper function to get the week number
  const getWeekNumber = (date) => {
    const start = new Date(date.getFullYear(), 0, 1);
    const weekNumber = Math.ceil(
      ((date - start) / 86400000 + start.getDay() + 1) / 7
    );
    return weekNumber;
  };

  return (
    <div className="max-w-4xl w-full mx-auto mt-10 p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold mb-6 text-center text-charcoal">
        {t("availabilityForNextThreeWeeks")}
      </h2>

      {/* Display weeks and corresponding checkboxes */}
      {Array.from({ length: 3 }, (_, i) => {
        const monday = new Date();
        monday.setDate(monday.getDate() + i * 7); // Get the Monday for each week
        const weekNumber = getWeekNumber(monday);

        return (
          <div key={i} className="mb-6">
            <div className="text-left mb-2">
              <strong>
                {t("week")} {weekNumber}: {monday.toLocaleDateString()}
              </strong>
            </div>
            <div className="grid grid-cols-7 gap-4">
              {availability.slice(i * 7, (i + 1) * 7).map((entry, idx) => (
                <div
                  key={idx}
                  className="border rounded p-4 flex flex-col items-center bg-white"
                >
                  <span className="font-semibold">
                    {new Date(entry.date).toLocaleDateString("default", {
                      weekday: "short",
                    })}
                  </span>
                  <div className="flex items-center mt-2">
                    <input
                      type="checkbox"
                      checked={entry.available}
                      onChange={(e) =>
                        handleAvailabilityChange(
                          idx + i * 7,
                          "available",
                          e.target.checked
                        )
                      }
                      className="mr-2"
                    />
                    {entry.available && (
                      <FontAwesomeIcon
                        icon={faComment}
                        className={`cursor-pointer ${
                          entry.comment ? "text-orange-500" : "text-gray-500"
                        } hover:text-orange-600 text-lg`}
                        onClick={() => openCommentModal(entry.date)}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      })}

      {/* Display messages above the submit button */}
      <div className="mb-4 text-red-600">
        {messages.map((message, index) => (
          <div key={index}>{message}</div>
        ))}
      </div>

      <div className="flex justify-center">
        <button
          onClick={handleSubmit}
          className="w-1/4 bg-persianGreen text-white py-1.5 px-4 rounded-md hover:bg-charcoal focus:outline-none focus:ring-2 focus:ring-persianGreen"
        >
          {t("save")}
        </button>
      </div>

      {/* Modal for Comment Input */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded shadow-lg max-w-lg w-full">
            <h3 className="text-lg font-bold mb-4">{t("addComment")}</h3>
            <textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className="border p-2 w-full h-24 rounded mb-4"
              placeholder={t("addComment")}
              rows="4"
            />
            <div className="flex justify-between">
              <button
                onClick={handleSubmitComment}
                className="bg-persianGreen text-white p-1 rounded hover:bg-charcoal"
              >
                {t("saveComment")}
              </button>
              <button
                onClick={closeCommentModal}
                className="bg-gray-500 text-white p-1 rounded hover:bg-gray-600"
              >
                {t("cancel")}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserSetAvailability;
