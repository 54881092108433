// Filename: src/pages/admin/AdminConfirmUserHours.js

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import config from "../../config";
import { formatNumber } from "../../utils/formatNumber"; // Import formatNumber
import { FaCheckCircle } from "react-icons/fa";

const AdminConfirmUserHours = () => {
  const { t } = useTranslation();
  const adminId = localStorage.getItem("userId");
  const [workedHours, setWorkedHours] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [loading, setLoading] = useState(false);
  const [confirming, setConfirming] = useState({});

  const fetchWorkedHours = (date) => {
    const tenantId = localStorage.getItem("tenantId");
    setLoading(true);
    fetch(
      `${config.API_URL}/admin/get-all-user-hours?date=${date}&tenantId=${tenantId}`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setWorkedHours(data.workedHours || []);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching worked hours:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchWorkedHours(selectedDate);
  }, [selectedDate]);

  const changeDateBy = (days) => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() + days);
    setSelectedDate(newDate.toISOString().substring(0, 10));
  };

  const confirmWorkedHours = async (workedHourId) => {
    setConfirming((prev) => ({ ...prev, [workedHourId]: true }));

    try {
      const response = await fetch(
        `${config.API_URL}/admin/confirm-worked-hours`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            workedHourId,
            adminId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to confirm worked hours");
      }

      setWorkedHours((prev) =>
        prev.map((entry) =>
          entry.id === workedHourId
            ? { ...entry, confirmed_by: adminId }
            : entry
        )
      );

      setConfirming((prev) => ({ ...prev, [workedHourId]: false }));
    } catch (error) {
      console.error("Error confirming worked hours:", error);
      setConfirming((prev) => ({ ...prev, [workedHourId]: false }));
    }
  };

  const isToday = new Date().toISOString().substring(0, 10) === selectedDate;

  return (
    <div className="max-w-4xl mx-auto mt-10 p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold mb-6 text-center">
        {t("users_worked_today")}
      </h2>

      <div className="mb-4 flex items-center justify-center space-x-4">
        <button
          onClick={() => changeDateBy(-1)}
          className="p-2 rounded bg-blue-500 text-white"
          aria-label="Previous Day"
        >
          <FaArrowLeft />
        </button>
        <input
          type="date"
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
          className="p-2 border rounded"
        />
        {!isToday && (
          <button
            onClick={() => changeDateBy(1)}
            className="p-2 rounded bg-blue-500 text-white"
            aria-label="Next Day"
          >
            <FaArrowRight />
          </button>
        )}
      </div>

      {loading ? (
        <p>{t("loading")}...</p>
      ) : (
        <table className="w-full border-collapse">
          <thead>
            <tr>
              <th className="border p-2">{t("username")}</th>
              <th className="border p-2">{t("start_time")}</th>
              <th className="border p-2">{t("end_time")}</th>
              <th className="border p-2">{t("total_worked_hours")}</th>
              <th className="border p-2">{t("confirm_hours")}</th>
            </tr>
          </thead>
          <tbody>
            {workedHours.length > 0 ? (
              workedHours.map((entry) => (
                <tr
                  key={entry.id}
                  className={!entry.confirmed_by ? "bg-red-200" : ""}
                >
                  <td className="border p-2">{entry.username}</td>
                  <td className="border p-2">
                    {new Date(entry.start_time).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}

                    {/* Format start time */}
                  </td>
                  <td className="border p-2">
                    {new Date(entry.end_time).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </td>
                  <td className="border p-2">
                    {formatNumber(entry.total_worked_hours)}{" "}
                    {/* Format total worked hours */}
                  </td>
                  <td className="border p-2">
                    {entry.confirmed_by ? (
                      <span className="text-green-500 font-bold">
                        <span className="text-green-500 font-bold">
                          <FaCheckCircle />
                        </span>
                      </span>
                    ) : (
                      <button
                        className={`p-2 rounded bg-blue-500 text-white ${
                          confirming[entry.id]
                            ? "opacity-50 cursor-not-allowed"
                            : ""
                        }`}
                        onClick={() => confirmWorkedHours(entry.id)}
                        disabled={confirming[entry.id]}
                      >
                        {confirming[entry.id] ? t("confirming") : t("confirm")}
                      </button>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="border p-2 text-center">
                  {t("no_entries_found")}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AdminConfirmUserHours;
