import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Import translation files
import enTranslation from "./locales/en/translation.json";
import nlTranslation from "./locales/nl/translation.json";
import enHomepage from "./locales/en/homepage.json"; // Import homepage translations
import nlHomepage from "./locales/nl/homepage.json"; // Import homepage translations

i18n
  .use(LanguageDetector) // Automatically detects the user's language
  .use(initReactI18next) // Passes i18n instance to react-i18next
  .init({
    debug: true, // Set this to false in productio
    missingKeyHandler: (lng, ns, key) => {
      // Suppress or log the missing key
      console.warn(`Missing translation for key: ${key} in language: ${lng}`);
    },
    resources: {
      en: {
        translation: enTranslation, // English translations
        homepage: enHomepage, // English homepage translations
      },
      nl: {
        translation: nlTranslation, // Dutch translations
        homepage: nlHomepage, // Dutch homepage translations
      },
    },
    fallbackLng: "en", // Use English if language not found
    interpolation: {
      escapeValue: false, // React already protects against XSS
    },
    ns: ["translation", "homepage"], // Define the namespaces
    defaultNS: "translation", // Default namespace
  });

export default i18n;
